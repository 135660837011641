import React, { useState, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Modal from 'react-modal';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import breakPoints from '../helpers/breakPoints';
import { generateUrl } from '../helpers/cloudinary';
import { Layout, SignUpBtn, PrimaryButton, StyledHeroImage, HeroTextContainer } from '../components';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.66)',
    },
};

// Modal.setAppElement('#___gatsby');

const StyledModal = styled(Modal)`
    display: flex;
    max-height: 90vh;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 40px;
    bottom: 40px;
    transform: translate(-50%, 0);
    left: 50%;
    width: 40%;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    outline: none;
    text-align: center;

    @media (max-width: ${breakPoints.mobile}px) {
        width: 90%;
    }

    @media (min-width: ${breakPoints.mobile}px) and (max-width: ${breakPoints.tablet}px) {
        width: 90%;
    }
`;

const ClostBtn = styled.button`
    height: 40px;
    width: 40px;
    right: -15px;
    top: -15px;
    border-radius: 50%;
    position: absolute;
`;

const SignUpContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    opacity: ${(props) => (props.submitting ? 0.2 : 1)};
`;

const SignUpImg = styled.div`
    height: 40%;
    background-position: center;
    background-size: cover;
    background-image: url(${generateUrl('Vietnam-18_aqtfje.jpg', breakPoints.mobile)});
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
`;

const FormControl = styled.div`
    margin-bottom: 1rem;
`;

const StyledVideo = styled.video`
    height: 90vh;
    margin: auto;

    @media (max-width: ${breakPoints.tablet}px) {
        display: none;
    }
`;

const StyledVideoFull = styled.video`
    height: 100vh;
    background-color: black;
    /* display: block; */
    width: 100%;

    @media (min-width: ${breakPoints.tablet}px) {
        display: none;
    }
`;

const StyledVideoTextContainer = styled.div`
    display: flex;
    width: 90%;
`;

const Success = () => (
    <div>
        <h2>Thank you for subscribing!</h2>
    </div>
);

const Error = ({ msg }) => {
    const createMarkup = () => ({ __html: msg });
    return <div dangerouslySetInnerHTML={createMarkup()} />;
};

Error.propTypes = {
    msg: PropTypes.shape({
        result: PropTypes.string,
        msg: PropTypes.string,
    }),
};

export const LandingPageTemplate = ({ sections }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [result, setResult] = useState({});
    const [submitting, setSubmitting] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => setModalOpen(true), 5000);
        return () => clearTimeout(timer);
    }, []);

    const handleSubmit = async (e) => {
        setSubmitting(true);
        e.preventDefault();
        addToMailchimp(emailAddress, { FNAME: name }).then((response) => {
            setSubmitting(false);
            setResult(response);
        });
    };

    return (
        <>
            <StyledHeroImage image={sections[0].image} className="hero">
                <HeroTextContainer>
                    <h2 className="is-size-4-mobile is-size-2-tablet is-size-2-widescreen">{sections[0].heading}</h2>
                    <h3 className="is-size-5-mobile is-size-4-tablet is-size-3-widescreen">{sections[0].subHeading}</h3>
                    <PrimaryButton as="a" href={sections[0].sectionLink.link} target="__blank">
                        {sections[0].sectionLink.linkText}
                    </PrimaryButton>
                </HeroTextContainer>
            </StyledHeroImage>
            <StyledHeroImage image={sections[1].image} className="hero">
                <HeroTextContainer right>
                    <h2 className="is-size-4-mobile is-size-2-tablet is-size-2-widescreen">{sections[1].heading}</h2>
                    <h3 className="is-size-5-mobile is-size-4-tablet is-size-3-widescreen">{sections[1].subHeading}</h3>
                    <PrimaryButton as="a" href={sections[0].sectionLink.link} target="__blank">
                        {sections[1].sectionLink.linkText}
                    </PrimaryButton>
                </HeroTextContainer>
            </StyledHeroImage>
            <StyledHeroImage image={sections[2].image} className="hero">
                <StyledVideoTextContainer>
                    <HeroTextContainer>
                        <h2 className="is-size-4-mobile is-size-2-tablet is-size-2-widescreen">
                            {sections[2].heading}
                        </h2>
                        <h3 className="is-size-5-mobile is-size-4-tablet is-size-3-widescreen">
                            {sections[2].subHeading}
                        </h3>
                        <PrimaryButton as="a" href={sections[0].sectionLink.link} target="__blank">
                            {sections[2].sectionLink.linkText}
                        </PrimaryButton>
                    </HeroTextContainer>
                    <StyledVideo controls autoPlay loop muted>
                        <source src="img/DOMI_FILM_WEB.mp4" type="video/mp4" />
                        This browser does not display the video tag.
                    </StyledVideo>
                </StyledVideoTextContainer>
            </StyledHeroImage>
            <StyledVideoFull controls autoPlay loop muted>
                <source src="img/DOMI_FILM_WEB.mp4" type="video/mp4" />
                This browser does not display the video tag.
            </StyledVideoFull>
            <StyledHeroImage image={sections[3].image} className="hero">
                <HeroTextContainer>
                    <h2 className="is-size-4-mobile is-size-2-tablet is-size-2-widescreen">{sections[3].heading}</h2>
                    <h3 className="is-size-5-mobile is-size-4-tablet is-size-3-widescreen">{sections[3].subHeading}</h3>
                    <PrimaryButton as={Link} to={sections[3].sectionLink.link}>
                        {sections[3].sectionLink.linkText}
                    </PrimaryButton>
                </HeroTextContainer>
            </StyledHeroImage>
            <StyledHeroImage image={sections[4].image} className="hero">
                <HeroTextContainer>
                    <h2 className="is-size-4-mobile is-size-2-tablet is-size-2-widescreen">{sections[4].heading}</h2>
                    <h3 className="is-size-5-mobile is-size-4-tablet is-size-3-widescreen">{sections[4].subHeading}</h3>
                    <PrimaryButton as={Link} to={sections[4].sectionLink.link}>
                        {sections[4].sectionLink.linkText}
                    </PrimaryButton>
                </HeroTextContainer>
            </StyledHeroImage>
            <StyledModal
                style={customStyles}
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                contentLabel="Mailing List"
            >
                <ClostBtn aria-label="close modal" onClick={() => setModalOpen(false)} className="button">
                    <MdClose style={{ position: 'absolute' }} size={32} />
                </ClostBtn>
                <SignUpImg />
                <SignUpContainer submitting={submitting}>
                    <h2 className="is-size-3-mobile is-size-3-tablet is-size-2-widescreen">Subscribe to SORORES</h2>
                    <p className="is-size-4-mobile is-size-4-tablet is-size-3-widescreen">
                        We won’t spam you, we promise. We only send exciting news and special offers. Sign up today for
                        10% off your next order.
                    </p>
                    {result.result === 'success' && <Success />}
                    {result.result === 'error' && <Error msg={result.msg} />}
                    {result.result !== 'success' && result.result !== 'error' && (
                        <form onSubmit={handleSubmit}>
                            <FormControl className="control">
                                <label className="label" htmlFor="name">
                                    Name
                                    <input
                                        className="input"
                                        type="text"
                                        name="name"
                                        onChange={(e) => setName(e.target.value)}
                                        id="name"
                                        required
                                    />
                                </label>
                            </FormControl>
                            <FormControl className="control">
                                <label className="label" htmlFor="email-field">
                                    Email
                                    <input
                                        className="input"
                                        type="email"
                                        name="email"
                                        onChange={(e) => setEmailAddress(e.target.value)}
                                        id="email-field"
                                        required
                                    />
                                </label>
                            </FormControl>
                            <SignUpBtn className="button" disabled={!name || !emailAddress} type="submit">
                                Sign Me Up
                            </SignUpBtn>
                        </form>
                    )}
                </SignUpContainer>
            </StyledModal>
        </>
    );
};

LandingPageTemplate.propTypes = {
    sections: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string.isRequired,
            heading: PropTypes.string.isRequired,
            subHeading: PropTypes.string.isRequired,
            sectionLink: PropTypes.shape({
                linkText: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
            }),
        })
    ),
};

const LandingPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;

    return (
        <Layout title={frontmatter.title} description={frontmatter.description} image={frontmatter.image}>
            <LandingPageTemplate sections={frontmatter.sections} />
        </Layout>
    );
};

LandingPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired,
                sections: PropTypes.arrayOf(
                    PropTypes.shape({
                        image: PropTypes.string.isRequired,
                        heading: PropTypes.string.isRequired,
                        subHeading: PropTypes.string.isRequired,
                        sectionLink: PropTypes.shape({
                            linkText: PropTypes.string.isRequired,
                            link: PropTypes.string.isRequired,
                        }),
                    })
                ),
            }),
        }),
    }),
};

export default LandingPage;

export const pageQuery = graphql`
    query LandingPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "landing-page" } }) {
            frontmatter {
                title
                description
                image
                sections {
                    image
                    heading
                    subHeading
                    sectionLink {
                        linkText
                        link
                    }
                }
            }
        }
    }
`;
